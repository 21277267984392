<template>
    <div class="mx-auto container container--fluid">
        <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-app id="inspire" class="">
            <template v-if="load==true">
                <v-data-table :headers="headers" :items="jobs_list" class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>Job/Internship </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                            </v-text-field>
                            <span style="width: 20px"></span>
                            <v-dialog v-model="dialog" >
                                <template v-slot:activator="{ on }">
                                    <v-btn color=" darken-4" dark class="mb-2 logi" v-on="on" @click="iseditable=!iseditable">Add New Job/Internship </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" outlined v-model="editedItem.title" label="Title*" dense :rules="[v => !!v || 'required']"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" outlined dense v-model="editedItem.company" label="Company*" :rules="[v => !!v || 'required']"></v-text-field>
                                                    <span v-if="editedIndex==-1"></span>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" outlined dense v-model="editedItem.location" label="Location" :rules="[v => !!v || 'required']"></v-text-field>
                                                    <span v-if="editedIndex==-1"></span>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" outlined dense v-model="editedItem.description" label="Description" :rules="[v => !!v || 'required']"></v-text-field>
                                                    <span v-if="editedIndex==-1"></span>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" outlined dense v-model="editedItem.yearly_package" label="Package in Lakh" :rules="[v => !!v || 'required']"></v-text-field>
                                                    <span v-if="editedIndex==-1"></span>
                                                </v-col>
                                                <!-- <v-col cols="12" sm="6" md="4">
                                                        <v-text-field dense v-model="editedItem.schedule_date" label="Schedule Date" :rules="[v => !!v || 'required']"></v-text-field>
                                                        <span v-if="editedIndex==-1"></span>
                                                </v-col> -->
                                               
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" outlined dense v-model="editedItem.company_wesbsite" label="Company Website*" :rules="[v => !!v || 'required']"></v-text-field>
                                                    <span v-if="editedIndex==-1"></span>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" outlined dense v-model="editedItem.company_address" label="Company Address*" :rules="[v => !!v || 'required']"></v-text-field>
                                                    <span v-if="editedIndex==-1"></span>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" outlined dense v-model="editedItem.company_contact_person_name" label="Company Person Name" :rules="[v => !!v || 'required']"></v-text-field>
                                                    <span v-if="editedIndex==-1"></span>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" outlined dense v-model="editedItem.company_contact" label="Company Contact*" :rules="[v => !!v || 'required']"></v-text-field>
                                                    <span v-if="editedIndex==-1"></span>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" outlined dense v-model="editedItem.company_email" label="Company Email" :rules="[v => !!v || 'required']"></v-text-field>
                                                    <span v-if="editedIndex==-1"></span>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" outlined dense v-model="editedItem.job_link" label="Job Link*" :rules="[v => !!v || 'required']"></v-text-field>
                                                    <span v-if="editedIndex==-1"></span>
                                                </v-col>
                        <!-- Recruitment type list -->
                                                <v-col cols="12" sm="6" md="4" v-if='editedIndex == -1'>
                                                    <v-autocomplete class="mx-2" dense outlined :items="rt_list"  v-model="editedItem.recruitmenttype" label="Select Recruitment Type* " :rules="[v => !!v || 'required']"></v-autocomplete>
                                                </v-col>
                                            
                                                <v-col cols="4" v-if='editedIndex != -1'> 
                                                    <v-select class="mx-2" outlined dense label="Recruitment Type" v-model="editedItem.recruitmenttype" :items="rt_list" item-value="id" :rules="[v => !!v || 'required']"></v-select>
                                                </v-col>

                            <!-- Industry List -->
                                                <v-col cols="12" sm="6" md="4" v-if='editedIndex == -1'>
                                                    <v-autocomplete class="mx-2" outlined dense :items="industry_list"  v-model="editedItem.industry" label="Select Industry* " :rules="[v => !!v || 'required']"></v-autocomplete>
                                                </v-col>
                                            
                                                <v-col cols="4" v-if='editedIndex != -1'>
                                                    <v-select class="mx-2" label="Industry" outlined dense v-model="editedItem.industry" :items="industry_list" item-value="id" :rules="[v => !!v || 'required']"></v-select>
                                                </v-col>

                            <!-- Placement Type list-->
                                                 <v-col cols="12" sm="6" md="4" v-if='editedIndex == -1'>
                                                    <v-autocomplete class="mx-2" outlined dense :items="pt_list"  v-model="editedItem.placementtype" label="Select Placement Type* " :rules="[v => !!v || 'required']"></v-autocomplete>
                                                </v-col>
                                            
                                                <v-col cols="4" v-if='editedIndex != -1'>
                                                    <v-select class="mx-2" label="Placement Type" outlined dense v-model="editedItem.placementtype" :items="pt_list" item-value="id" :rules="[v => !!v || 'required']"></v-select>
                                                </v-col>
                            <!-- Internship Type list-->

                                                 <v-col cols="12" sm="6" md="4" v-if='editedIndex == -1'>
                                                    <v-autocomplete class="mx-2" outlined dense :items="it_list"  v-model="editedItem.internshiptype" label="Select Internship Type* " :rules="[v => !!v || 'required']"></v-autocomplete>
                                                </v-col>
                                            
                                                <v-col cols="4" v-if='editedIndex != -1'>
                                                    <v-select class="mx-2" label="Internship Type" outlined dense v-model="editedItem.internshiptype" :items="it_list" item-value="id" :rules="[v => !!v || 'required']"></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-file-input class="mx-2" label="Document Attachment" persistent-hint:hint="'File Size Should be less than 250 kb,JPEG, JPG & PDF'"
                                                    accept=".pdf, .jpg, .jpeg" prepend-icon="" :prepend-inner-icon="fileIcon" ref="file"  show-size clearable
                                                    v-model="editedItem.file" outlined></v-file-input>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" label="Schedule Date*" outlined type="date" format="yyyy-MM-dd" v-model="editedItem.schedule_date" />
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field class="mx-2" label="Valid Upto Date*" type="date" format="yyyy-MM-dd" outlined v-model="editedItem.valid_upto_date" />
                                                </v-col>
                                                
                                                                                        
                                               
                                                 <v-col cols="12" sm="2" md="2" v-if='editedIndex != -1'>
                                                    <v-checkbox class="mx-2" dense v-model="editedItem.isactive" color="success" label="Active"></v-checkbox>
                                                 </v-col>
                                                <v-col cols="12" sm="6" md="4" v-if='editedIndex != -1'>
                                                    <input type="hidden" v-model="editedItem.id" name="id" />
                                                    <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                                                </v-col>
                                                
                                            </v-row>

                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                        <v-btn color="primary darken-1" @click="save">Save</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.document_file`]="{ item }">
          <a :href="item.document_file" target="_blank"><v-icon icon="mdi-eye">mdi-eye {{ item.document_file }}</v-icon></a>
        </template>
                    <template v-slot:item.action="{ item }">
                        <div>
                            <v-avatar style="" size="25" class="edit-avatar">
                                <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
                            </v-avatar>
                        </div>
                    </template>
                    <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                        <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                        <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                    </template>
                </v-data-table>
            </template>
        </v-app>
    </div>
</template>

<script>
    import axios from "axios";
    import datetime from 'vuejs-datetimepicker'
    export default {
         components : { datetime },
        data: () => ({
            overlay: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            iseditable: false,
            load: false,
            dialog: false,
            jobs_list: null,
            rt_list: null,
            pt_list: null,
            it_list: null,
            industry_list: null,
            errorMsg: {
                id: false
            },
            search: '',
            headers: [
            {
                    text: "Actions",
                    value: "action",
                    sortable: false
                },
                {
                   text: 'Active',align: 'left',sortable: true,value: 'isactive',
                 },
                 {
                    text: "Document Attachment",
                    value: "document_file"
                },
                 {
                     text: "Schedule Date",
                     value: "schedule_date"
                 },
                 {
                    text: "Valid Upto Date",
                    value: "valid_upto_date"
                 },
                {
                    text: "Title",
                    value: "title"
                }, {
                    text: "Company",
                    value: "company"
                }, {
                    text: "Location",
                    value: "location"
                }, {
                    text: "Description",
                    value: "description"
                }, {
                    text: "Package",
                    value: "yearly_package"
                },
                
                {
                    text: "Company Wesbsite",
                    value: "company_wesbsite"
                }, {
                    text: "Company Address",
                    value: "company_address"
                }, {
                    text: "Company Person Name",
                    value: "company_contact_person_name"
                }, {
                    text: "Company Contact",
                    value: "company_contact"
                }, {
                    text: "Company Email",
                    value: "company_email"
                }, {
                    text: "Job Link",
                    value: "job_link"
                }, {
                    text: "Recruitment Type",
                    value: "recruitmenttype"
                }, {
                    text: "Placement Type",
                    value: "placementtype"
                }, {
                    text: "Internship Duration",
                    value: "internshiptype"
                },
                {
                    text: "Industry",
                    value: "industry"
                },
            ],
            fileIcon: 'mdi-file',
            editedIndex: -1,
            editedItem: {
                id: '',
                title: '',
                recruitmenttype: '',
                placementtype: '',
                internshiptype: '',
                company: '',
                location: '',
                description: '',
                yearly_package: '',
                schedule_date:'',
                schedule_date1:'',
                company_wesbsite: '',
                company_address: '',
                company_contact_person_name: '',
                company_contact: '',
                company_email: '',
                job_link: '',
                industry:'',
                valid_upto_date: '',
                file: null,
                 isactive:true 
            },
            defaultItem: {
                id: '',
                title: '',
                recruitmenttype: '',
                placementtype: '',
                internshiptype: '',
                company: '',
                location: '',
                description: '',
                yearly_package: '',
                 schedule_date:'',
                 schedule_date1:'',
                company_wesbsite: '',
                company_address: '',
                company_contact_person_name: '',
                company_contact: '',
                company_email: '',
                job_link: '',
                industry:'',
                valid_upto_date: '',
                file: null,
                isactive:true 
            }
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Job/Internship " : "Edit Job/Internship ";
            }
        },

        watch: {
            dialog(val) {
                    val || this.close();
                },
                overlay(val) {
                    val = () => {
                        this.overlay = false
                    };
                },
        },
        mounted() {
          this.onload()
        },

        methods: {
            onload(){
                  axios
                .post("/FacultyAlumniData/addJobInternship")
                .then(res => {
                    if (res.data.msg == "200") {
                        this.jobs_list = res.data.jobs_list
                        this.rt_list = res.data.rt_list
                        this.pt_list = res.data.pt_list
                        this.it_list = res.data.it_list
                        this.industry_list = res.data.industry_list
                        this.load = true;
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg); 
                    }
                })
                .catch(error => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    // window.console.log(error);
                });
            },
            initializeErrorMsgFalse() {
                    this.errorMsg.id = false;
                },
                editItem(item) {
                    this.iseditable = false;
                    this.editedIndex = this.jobs_list.indexOf(item);
                    this.editedItem = Object.assign({}, item);
                    this.dialog = true;
                },
                showSnackbar(clr, msg) {
                    this.snackbar = true;
                    this.color = clr;
                    this.snackbar_msg = msg;
                },
                close() {
                    this.dialog = false;
                    setTimeout(() => {
                        this.editedItem = Object.assign({}, this.defaultItem);
                        this.initializeErrorMsgFalse();
                        this.editedIndex = -1;
                    }, 300);
                },

                save() {
                    if(!this.editedItem.company){
                        this.showSnackbar("#b71c1c", "Please Enter Company Name.."); 
                        return
                    }
                    if(!this.editedItem.yearly_package){
                        this.showSnackbar("#b71c1c", "Please Enter Package.."); 
                        return
                    }

                    if (this.editedIndex > -1) {
                        //  console.log("edit.." + this.editedItem);
                        this.iseditable = false;
                        let formData = new FormData();
                        formData.append("id", this.editedItem.id);
                        formData.append("title", this.editedItem.title);
                        formData.append("company", this.editedItem.company);
                        formData.append("location", this.editedItem.location);
                        formData.append("description", this.editedItem.description);
                        formData.append("yearly_package", this.editedItem.yearly_package);
                        formData.append("company_website", this.editedItem.company_wesbsite);
                        formData.append("company_address", this.editedItem.company_address);
                        formData.append("company_contact_person_name", this.editedItem.company_contact_person_name);
                        formData.append("company_contact", this.editedItem.company_contact);
                        formData.append("company_email", this.editedItem.company_email);
                        formData.append("job_link", this.editedItem.job_link);
                        formData.append("recruitmenttype", this.editedItem.recruitmenttype);
                        formData.append("industry", this.editedItem.industry);
                        formData.append("placementtype", this.editedItem.placementtype);
                        formData.append("internshiptype", this.editedItem.internshiptype);
                        formData.append("schedule_date", this.editedItem.schedule_date);
                        formData.append("valid_upto_date", this.editedItem.valid_upto_date);
                        formData.append("isactive", this.editedItem.isactive);
                        formData.append("file", this.editedItem.file);
                        axios
                            .post("/FacultyAlumniData/editJobInternship", formData, {
                                headers: {
                                "Content-Type": "multipart/form-data",
                            },
                            })
                            .then(res => {
                                if (res.data.status == "200" || res.data.status == 200) {
                                    // Object.assign(this.jobs_list[this.editedIndex], this.editedItem);
                                    
                                    this.showSnackbar(
                                        "#4caf50",
                                        "Job/Internship  Updated Successfully..."
                                    ); // show snackbar on success
                                    this.onload();
                                }
                            })
                            .catch(error => {
                                this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                                // window.console.log(error);
                            });
                    } else {
                        let formData = new FormData();
                        formData.append("id", this.editedItem.id);
                        formData.append("title", this.editedItem.title);
                        formData.append("company", this.editedItem.company);
                        formData.append("location", this.editedItem.location);
                        formData.append("description", this.editedItem.description);
                        formData.append("yearly_package", this.editedItem.yearly_package);
                        formData.append("company_website", this.editedItem.company_wesbsite);
                        formData.append("company_address", this.editedItem.company_address);
                        formData.append("company_contact_person_name", this.editedItem.company_contact_person_name);
                        formData.append("company_contact", this.editedItem.company_contact);
                        formData.append("company_email", this.editedItem.company_email);
                        formData.append("job_link", this.editedItem.job_link);
                        formData.append("recruitmenttype", this.editedItem.recruitmenttype);
                        formData.append("industry", this.editedItem.industry);
                        formData.append("placementtype", this.editedItem.placementtype);
                        formData.append("internshiptype", this.editedItem.internshiptype);
                        formData.append("schedule_date", this.editedItem.schedule_date);
                        formData.append("valid_upto_date", this.editedItem.valid_upto_date);
                        formData.append("isactive", this.editedItem.isactive);
                        formData.append("file", this.editedItem.file);
                        axios
                            .post("/FacultyAlumniData/saveJobInternship", formData, {
                                headers: {
                                "Content-Type": "multipart/form-data",
                            },
                            })
                            .then(res => {
                                if (res.data.status.code == "SUCCESS") {
                                    this.jobs_list.push(this.editedItem);
                                    this.showSnackbar("#4caf50", "New Job/Internship  Added Successfully!!!"); // show snackbar on success
                                        this.onload();
                                } else if (res.data.status.code == "NA") {
                                    //alert(res.data.status.message)
                                    this.showSnackbar("#b71c1c", res.data.status.message); // show snackbar on error
                                }
                            })
                            .catch(error => {
                                window.console.log(error);
                            });
                    }
                    this.close();
                },
                isValidated() {
                    if (this.editedItem.id) {
                        return true;
                    } else {
                        if (!this.editedItem.id) {
                            this.errorMsg.id = true
                        }

                        return false;
                    }
                }, // .....end of isValidated()
        }
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    
    .v-icon-size {
        font-size: 20px;
    }
    
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    
    .edit-v-icon:hover {
        color: white;
    }
</style>